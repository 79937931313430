import icon from "../images/icons/special.svg";
import { END_DATE, NOW } from "./constants";

const subtitle = NOW < END_DATE ? 'ТОЛЬКО ДО 14 ФЕВРАЛЯ' : 'ТОЛЬКО 3 ДНЯ';

const DEFAULT_DATA = {
  icon: icon,
  title_line: "на особых условиях",
  subtitle: subtitle,
  btn_text: "Подробности в отделе продаж",
};

export default [
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI TUCSON",
    slider: Array.from(Array(55)).map((_, i) =>
      require(`../images/slides/tucson/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI PALISADE",
    slider: Array.from(Array(30)).map((_, i) =>
      require(`../images/slides/palisade/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI SANTA FE",
    slider: Array.from(Array(17)).map((_, i) =>
      require(`../images/slides/santafe/${i + 1}.jpg`)
    ),
  },
  {
    ...DEFAULT_DATA,
    model_name: "HYUNDAI STARIA",
    slider: Array.from(Array(22)).map((_, i) =>
      require(`../images/slides/staria/${i + 1}.jpg`)
    ),
  },
]

// import breakpoints from "../styles/breakpoints.scss";
import breakpoints from "../styles/temp.module.scss";
import { END_DATE, NOW } from "./constants";

const sortedBreakpoints = Object.entries(breakpoints)
	.map(([key, size]) => ({ key, size: +size.replace(/[^\d]/gi, '') }))
	.sort((lhs, rhs) => {
		return rhs.size - lhs.size
	});
// let sortedBreakpoints = [
// 	{key: 'desktop', size: 1200},
// 	{key: 'tablet', size: 640},
// 	{key: 'mobile', size: 0},
// ]
console.log(sortedBreakpoints)

export default {
	emits: ["getCall"],
	data() {
		return {
			device_platform: "desktop",
			device_width: 0,
		};
	},
	mounted() {
		let handleResize = () => {
			const initial = sortedBreakpoints;
			let width = initial[0];
			for (let i = 0; i < initial.length; ++i) {
				if (window.innerWidth > initial[i].size) {
					width = initial[i];
					break;
				}
			}
			this.device_platform = width.key;
			this.device_width = window.innerWidth;
		};

		window.addEventListener("resize", handleResize);
		handleResize();
	},
	computed: {},
	methods: {
		getCall(type, event) {
			if (event && !type.text) {
				if (type.deep) {
					type.text = getCallbackNode(event.target).innerHTML;
				} else {
					type.text = event.target.innerHTML;
				}
			}
			// ym(57632524, 'reachGoal', 'open_form');
			this.$emit("getCall", type);
		},
		getScrollWidth() {
			let div = document.createElement('div');
			div.style.overflowY = 'scroll';
			div.style.width = '50px';
			div.style.height = '50px';
			div.style.position = 'absolute';
			div.style.zIndex = '-100';
			// мы должны вставить элемент в документ, иначе размеры будут равны 0
			document.body.append(div);
			let scrollWidth = div.offsetWidth - div.clientWidth;
			div.remove();
			return scrollWidth;
		},
		blockScroll() {
			document.body.classList.add("scroll-blocked");
			document.body.style.width = `calc(100% - ${this.getScrollWidth()}px)`;
		},
		getAgreement() {
			this.$emit("getAgreement");
		},
		unblockScroll() {
			document.body.classList.remove("scroll-blocked");
			document.body.style.width = 'initial';
		},
		isNowBefore() {
			return NOW < END_DATE;
	  }
	}
};

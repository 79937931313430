<template>
  <div class="block-corporate" :class="{ 'valentine_day': isNowBefore() }">
    <div class="block-corporate__container container">
      <div class="block-corporate__title">
        Продажа корпоративного парка HYUNDAI
      </div>

      <ul class="block-corporate__list">
        <li v-for="listItem in advantagesList" class="block-corporate__list-item">
          {{listItem}}
        </li>
      </ul>

      <button
        @click="getCall({ type: 'sale', form: 'corporate' })"
        class="button block-corporate__button"
      >
        Получить предложение
      </button>
    </div>
  </div>
</template>

<script>
import Mixin from "../common/mixin";

export default {
  mixins: [Mixin],
  data() {
    return {
      advantagesList: [
      'Большой выбор авто с НДС',
      'Специальные условия для юридических лиц',
      'Гарантия 2 года или 100 000 км пробега на все автомобили!'
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@use '@/styles/constants.scss' as *;

.block-corporate {
  background: url('../images/bacground/corporate.jpg') no-repeat center/cover;
  height: 450px;

  &__container {
    padding-top: 75px;
    height: 100%;
    color: #fff;
  }

  &__title {
    font-weight: 700;
    font-size: 33px;
  }

  &__list {
    margin: 25px 0 0 14px;
    width: max-content;
    
    &-item {
      font-weight: 500;
      font-size: 20px;
      line-height: 170%;
    }
  }

  &__button {
    margin-top: 40px;
    font-size: 25px;
    color: #002c5f;
  }
}

@media (max-width: 1600px) {
  .block-corporate {
    background-position-x: 80%;
  }
}

@media (max-width: 800px) {
  .block-corporate {
    background: url('../images/bacground/corporate_m.jpg') no-repeat center/cover;
    height: calc(100vw * 867 / 600);

    &__container {
      padding: calcFontSize(18, 45, 320, 800) calcFontSize(12, 50, 320, 800) 0;
    }

    &__title {
      font-size: calcFontSize(23, 33, 320, 800);
    }

    &__list {
      width: auto;
      margin-top: calcFontSize(10, 25, 320, 800);

      &-item {
        font-size: calcFontSize(14, 20, 320, 800);
        line-height: 150%;
      }
    }

    &__button {
      width: 100%;
      font-size: calcFontSize(16, 25, 320, 800); 
      margin-top: calcFontSize(15, 40, 320, 800);
    }
  }
}
</style>

<style lang="scss" scoped>
// Удалить после 15.02.2025
@use '@/styles/constants.scss' as *;

.valentine_day {
  background: url('../images/bacground/v_d_corporate.jpg') no-repeat center/cover;
}

@media (max-width: 800px) {
  .valentine_day {
    background: linear-gradient(rgba(#000, 0.5) 30%, rgba(#000, 0) 50%), 
    url('../images/bacground/v_d_corporate.jpg') no-repeat;
    background-position: 60%;
  }
}
// Удалить после 15.02.2025
</style>